

























































































































































































































































































































































import { Vue, Component } from 'vue-property-decorator'
import { getUserDetail, getStateList, uploadImg, getCountryList, getDefaultAddr, checkZipCode } from '@/api/user'
import { customerApplyActivity, getActivityDetail } from '@/api/activity'
import AfterHeader from '@/views/login/component/aftereader.vue'

@Component({
  components: {
    AfterHeader
  }
})
export default class ActApply extends Vue {
  toast:any={}
  titleSec: any = 'Shipping address'
  backText:any='< Back'
  showF2: any = true
  imgList: Array<any> = []
  form2: any = {
    customer_address_id: '',
    first_name: '',
    last_name: '',
    address: '',
    apartment: '',
    city: '',
    country: '',
    state: '',
    zip_code: '',
    phone: '',
    allow_active_contact: 0
  }
  showLoading: boolean = false
  countryList: any = []
  cityerrorText: any = ''
  fir2errorText: any = ''
  isShowFir2Error: any = null
  last2errorText: any = ''
  isShowLast2Error: any = null
  addresserrorText: any = ''
  isShowAddressError: any = null
  apartmenterrorText: any = ''
  isShowApartmentError: any = null
  isShowCityError: any = null
  phone_code:any = ''
  stateList: any = []
  countryerrorText: any = ''
  isShowCountryError: any = null
  stateerrorText: any = ''
  zip_codeerrorText: any = ''
  isAgreeerrorText:any = ''
  isShowStateError: any = null
  isShowZipCodeError: any = null
  isShowAgreeError: any = null
  phone_notice: any = true
  phoneerrorText: any = ''
  isShowPhoneError: any = null
  form3Page: any = false
  files: any = null
  isShowImgContent: any =false
  form2Page: any =false
  id: any=this.$route.query.activity_id
  activityDetails: any = {}
  time: number=0
  noClose: boolean = true
  item: number=0
  getItem(i:any) {
    this.item = i
  }
  gotoCenter() {
    this.$router.push({
      path: 'user'
    })
  }
  closeFn(bvModalEvt: any) {
    if (this.noClose) {
      bvModalEvt.preventDefault()
    }
  }
  submit() {
    this.form2Page = true
    customerApplyActivity({
      ...this.form2,
      allow_active_contact: Number(this.form2.allow_active_contact),
      phone_code: this.phone_code,
      activity_id: this.$route.query.activity_id
    }).then(res => {
      if (res.status_code === 200) {
        this.time = 5
        const t = setInterval(() => {
          this.time -= 1
          if (this.time <= 0) {
            clearInterval(t)
          }
        }, 1000);
        (this as any).$refs['iknow-modal'].show()
      } else {
        this.toast = {
          show: true,
          type: 'error',
          massage: res.message,
          position: 'top',
          duration: 5000
        }
      }
    }).finally(() => {
      this.form2Page = false
    })
  }
  iknowFn() {
    (this as any).$refs['my-modal'].show()
    this.noClose = false
    this.$nextTick(() => {
      (this as any).$refs['iknow-modal'].hide()
    })
  }
  handleOk() {
    (this as any).delImg()
  }
  delImg() {
    this.imgList.splice(this.item, 1)
  }
  inputImage(flie:any) {
    if (!flie.length) {
      return false
    }
    if ((this.imgList.length + flie.length) > 8) {
      (this as any).$refs['more-modal'].show()
      return false
    }
    this.form2Page = true
    // 循环添加到formData中
    const formData = new FormData()
    for (let i = 0; i < this.files.length; i++) {
      formData.append('file[' + i + ']', this.files[i])
    }
    // 上传图片事件
    // var files = flie
    // formData.append('file', flie)
    formData.append('type', '1')
    uploadImg(formData).then(res => {
      res.data.map((item:any) => {
        this.imgList.push(item.url)
        this.files = []
      })
    }).finally(() => {
      this.form2Page = false
    })
  }
  nextFn() {
    const list = Object.keys(this.form2)
    for (const key of list) {
      if (key !== 'customer_address_id') {
        const item = this.form2[key]
        if (!(this as any)[`${key}Input`](item, 1)) {
          return false
        }
      }
    }
    this.form2Page = true
    checkZipCode({ zip_code: this.form2.zip_code }).then(res => {
      if (res.status_code === 200) {
        (this as any).$refs['comfirm-modal'].show()
      } else {
        this.toast = {
          show: true,
          type: 'error',
          massage: res.message,
          position: 'top',
          duration: 5000
        }
      }
    }).finally(() => {
      this.form2Page = false
    })
  }
  gotoNext() {
    this.isShowImgContent = true
    this.titleSec = 'Review experience'
    document.body.scrollTop = document.documentElement.scrollTop = 0
  }
  goBackImg() {
    this.isShowImgContent = false
    this.titleSec = 'Shipping address'
    document.body.scrollTop = document.documentElement.scrollTop = 0
  }
  goBack() {
    this.$router.go(-1)
  }
  first_nameInput(val:any) {
    if (!val.length) {
      this.fir2errorText = 'Please enter it!'
      this.isShowFir2Error = false
    } else {
      // this.isShowFir2Error = true
      if (val.length > 50) {
        this.fir2errorText = `That's an invalid first name.`
        this.isShowFir2Error = false
      } else {
        this.isShowFir2Error = true
      }
    }
    return this.isShowFir2Error
  }
  last_nameInput(val:any) {
    if (!val.length) {
      this.last2errorText = 'Please enter it!'
      this.isShowLast2Error = false
    } else {
      if (val.length > 50) {
        this.last2errorText = `That's an invalid first name.`
        this.isShowLast2Error = false
      } else {
        this.isShowLast2Error = true
      }
    }
    return this.isShowLast2Error
  }
  addressInput(val:any) {
    if (!val.length) {
      this.addresserrorText = 'Please enter it!'
      this.isShowAddressError = false
    } else {
      if (val.length > 100) {
        this.addresserrorText = `That's an invalid address.`
        this.isShowAddressError = false
      } else {
        this.isShowAddressError = true
      }
    }
    return this.isShowAddressError
  }
  apartmentInput(val:any) {
    if (val && val.length > 100) {
      this.apartmenterrorText = `That's an invalid address.`
      this.isShowApartmentError = false
    } else {
      this.isShowApartmentError = true
    }
    return this.isShowApartmentError
  }
  cityInput(val:any) {
    if (!val.length) {
      this.cityerrorText = 'Please enter it!'
      this.isShowCityError = false
    } else {
      if (val.length > 100) {
        this.cityerrorText = `That's an invalid City.`
        this.isShowCityError = false
      } else {
        this.isShowCityError = true
      }
    }
    return this.isShowCityError
  }
  countryInput(val:any, code: any) {
    if (this.form2.country === 'US') {
      // this.form2.phone = '+1'
      this.phone_code = '+1'
    }
    if (!code) {
      getStateList({
        country_id: val
      }).then(res => {
        this.stateList = res.data
      })
    }
    if (!val) {
      this.countryerrorText = `Please Select one`
      this.isShowCountryError = false
    } else {
      this.isShowCountryError = true
    }
    return this.isShowCountryError
  }
  stateInput(val:any) {
    if (!val) {
      this.stateerrorText = `Please Select one`
      this.isShowStateError = false
    } else {
      this.isShowStateError = true
    }
    return this.isShowStateError
  }
  zip_codeInput(val:any) {
    if (!val) {
      this.zip_codeerrorText = `Please Enter it`
      this.isShowZipCodeError = false
    } else {
      this.isShowZipCodeError = true
    }
    return this.isShowZipCodeError
  }
  phoneInput(val:any) {
    this.phone_notice = false
    if (!val) {
      this.phoneerrorText = `Please Enter a valid phone number`
      this.isShowPhoneError = false
    } else {
      if (/^[0-9]*$/.test(val)) {
        this.isShowPhoneError = true
      } else {
        this.phoneerrorText = `Please Enter a valid phone number`
        this.isShowPhoneError = false
      }
    }
    return this.isShowPhoneError
  }
  allow_active_contactInput(val:any) {
    if (!val || val === '0') {
      this.isAgreeerrorText = `Please check the notice and continue`
      this.isShowAgreeError = false
    } else {
      this.isShowAgreeError = true
    }
    return this.isShowAgreeError
  }
  async getData() {
    const { data } = await getActivityDetail({ id: Number(this.id) })
    this.activityDetails = data
    const form3 = await getCountryList({})
    this.countryList = form3.data
    const form2 = await getDefaultAddr({})
    const userInfo = localStorage.getItem('userInfo')

    if (form2.data.first_name) {
      this.form2 = {
        customer_address_id: form2.data.customer_address_id,
        first_name: form2.data.first_name,
        last_name: form2.data.last_name,
        address: form2.data.address,
        apartment: form2.data.apartment,
        city: form2.data.city,
        state: form2.data.state,
        country: form2.data.country,
        zip_code: form2.data.zip_code,
        phone: form2.data.phone,
        allow_active_contact: 0
      }
      getStateList({
        country_id: form2.data.country
      }).then(res => {
        this.stateList = res.data
      })
      const form1 = await getUserDetail({})
      this.imgList = form1.data.experience_images
    } else {
      const tem = JSON.parse(userInfo as any)
      this.form2.first_name = tem.first_name
      this.form2.last_name = tem.last_name
    }
  }
  show() {
    const viewer = (this.$el as any).querySelector('.demo-img').$viewer
    viewer.show()
  }

  mounted() {
    this.getData()
  }
}

