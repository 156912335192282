






import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({})
export default class Headers extends Vue {
  @Prop({ default: 'default value' })
  titleSec!: string
}

