import request from '../utils/request'
// forgot password
interface BasUserDetailinfo {}
export function getUserInfo(data: BasUserDetailinfo): Promise<any> {
  return request({
    url: '/customer/getUserDetail',
    method: 'get',
    data
  })
}
// join act
interface BasActListinfo {}
export function getActList(data: BasActListinfo): Promise<any> {
  return request({
    url: '/customer/myActivityList',
    method: 'get',
    data
  })
}
// user basInfo
interface BasUserinfo {}
export function getUserDetail(data: BasUserinfo): Promise<any> {
  return request({
    url: '/customer/getUserDetail',
    method: 'get',
    data
  })
}
// user basInfo
interface BasAddressinfo {}
export function getDefaultAddr(data: BasAddressinfo): Promise<any> {
  return request({
    url: '/customer/getDefaultAddr',
    method: 'get',
    data
  })
}

// common/upload-img
interface BasuploadImginfo {}
export function uploadImg(data: BasuploadImginfo): Promise<any> {
  return request({
    url: '/common/upload-multi-img',
    method: 'post',
    data
  })
}

// common/upload-img
interface uploadfileinfo {}
export function uploadAllFile(data: uploadfileinfo): Promise<any> {
  return request({
    url: '/common/upload-file',
    method: 'post',
    data
  })
}

// 获取国家列表
interface BasCountryImginfo {}
export function getCountryList(params: BasCountryImginfo): Promise<any> {
  return request({
    url: '/select/getCountryList',
    method: 'get',
    params
  })
}

// 获取state列表
interface BasStateImginfo {
  country_id: Number
}
export function getStateList(params: BasStateImginfo): Promise<any> {
  return request({
    url: '/select/getRegionList',
    method: 'get',
    params
  })
}
// 用户活动节点详情

interface BasCustomerNodesImginfo {
  customer_activity_id: Number
}
export function getCustomerNodes(params: BasCustomerNodesImginfo): Promise<any> {
  return request({
    url: '/customer/getCustomerNodes',
    method: 'get',
    params
  })
}
// 编辑用户基本信息
interface BaseditUserInfoImginfo {
  first_name: string
  last_name: string
}
export function editUserInfo(data: BaseditUserInfoImginfo): Promise<any> {
  return request({
    url: '/customer/editUserInfo',
    method: 'post',
    data
  })
}
// 编辑经历证明图
interface BasExperienceImagesinfo {
  experience_images: any
}
export function editExperienceImages(data: BasExperienceImagesinfo): Promise<any> {
  return request({
    url: '/customer/editExperienceImages',
    method: 'post',
    data
  })
}
// 编辑用户地址
interface BaseditUserAddrImginfo {
  customer_address_id: string
  first_name: string
  last_name: string
  address: string
  apartment: string
  city: string
  country: any
  state: any
  zip_code: string
  phone: string
}
export function editUserAddr(data: BaseditUserAddrImginfo): Promise<any> {
  return request({
    url: '/customer/editUserAddr',
    method: 'post',
    data,
    isCustom: true
  })
}

interface checkEmailUrl {
  kind: any
  token: any
}
export function checkEmailToken(data: checkEmailUrl): Promise<any> {
  return request({
    url: '/email/check-email-url',
    method: 'post',
    data,
    isCustom: true
  })
}
// 判断zip_code有效性
interface BaseZipCodeinfo {
  zip_code: string
}
export function checkZipCode(data: BaseZipCodeinfo): Promise<any> {
  return request({
    url: '/customer/checkZipCode',
    method: 'post',
    data,
    isCustom: true
  })
}
