import request from '@/utils/request'

interface Detail {
  id: number
}
export function getActivityDetail(params: Detail): Promise<any> {
  return request({
    url: '/activity/content/detail',
    method: 'get',
    params
  })
}

// Determine if there is a application survey in activity  - Application Survey
// params = { activity_id }  Activity id
export function hasApplicationCollector(params: any): Promise<any> {
  return request({
    url: 'questionnaire/has-application-collector',
    method: 'get',
    params
  })
}

export function hasFinishAnotherActivity(): Promise<any> {
  return request({
    url: '/customer-activity/has-finish-another-activity',
    method: 'get'
  })
}

interface IsJoin {
  activity_id: Number
}
export function IsJoinAct(params: IsJoin): Promise<any> {
  return request({
    url: '/customer/hasApply',
    method: 'get',
    params
  })
}
// 编辑用户地址
interface BaseditUserAddrImginfo {
  customer_address_id: string
  first_name: string
  last_name: string
  address: string
  apartment: string
  city: string
  country: any
  state: any
  zip_code: string
  phone: string
  is_agree: any
  activity_id: any
  experience_images: any
}
export function customerApplyActivity(data: BaseditUserAddrImginfo): Promise<any> {
  return request({
    url: '/customer/applyActivity',
    method: 'post',
    data
  })
}
//
interface Page {
  page: number
  page_size: number
}
export function getActivityContentList(params: Page): Promise<any> {
  return request({
    url: '/activity/content/list',
    method: 'get',
    params
  })
}

interface Email {
  email: string
}

export function setEmail(params: Email): Promise<any> {
  return request({
    url: '/activity/email',
    method: 'post',
    params
  })
}

// 获取活动状态
interface id {
  id: Number
}
export function activityStatus(params: id): Promise<any> {
  return request({
    url: '/activity/status',
    method: 'get',
    params
  })
}
